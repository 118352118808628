import ListingsHelper from "@/utils/ListingsHelper";
import prisma, {
  Paginated,
  getPaginated,
  paginateModel,
} from "../../../prisma";

export default class LocationController {
  static async getAllPaginated(
    level: number,
    parentId: string = null,
    page: number = 1,
    pageSize: number = 1000
  ): Promise<Paginated> {
    try {
      let where: any;

      if (parentId) {
        let parentLevelWhere = [
          {
            level_1_parent_id: parseInt(parentId),
          },
          {
            level_2_parent_id: parseInt(parentId),
          },
        ];

        let _where = {};

        if (level) {
          _where = {
            level: {
              equals: level,
            },
            OR: parentLevelWhere,
          };
        } else {
          _where = {
            OR: parentLevelWhere,
          };
        }

        where = _where;
      } else {
        where = {
          level: {
            equals: level,
          },
        };
      }

      return await paginateModel(prisma.location as any, where, page, pageSize);
    } catch (error) {
      throw error;
    }
  }

  static async getByKeywordPaginated(
    keyword: string,
    parentId: number = null,
    page: number = 1,
    pageSize: number = 10
  ): Promise<Paginated> {
    try {
      let where: any = {};

      if (keyword) {
        const searchTerms =
          ListingsHelper.getFullTextSearchArrayFromStr2(keyword);
        where = {
          OR: searchTerms.map((term) => ({
            full_name: {
              contains: term,
              mode: "insensitive",
            },
          })),
        };
      } else {
        if (!parentId) {
          where = {
            level: {
              equals: 1,
            },
          };
        }
      }

      if (parentId) {
        where.level_1_parent_id = parentId;
      }

      let query = {
        where,
        include: {
          level_2_parent: true,
          level_1_parent: true,
        },
        orderBy: {
          full_name: "asc",
        },
      } as any;

      return await getPaginated(prisma.location as any, query, page, pageSize);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async getOne(uuid: string): Promise<any> {
    try {
      let where = {
        uuid: uuid,
      };

      let query = {
        where,
        include: {
          level_2_parent: true,
          level_1_parent: true,
        },
      } as any;

      return await prisma.location.findFirst(query);
    } catch (error) {
      throw error;
    }
  }

  static async getOneByIdWithLastChildren(id: number): Promise<any> {
    id = parseInt(id as any);
    try {
      let where = {
        OR: [
          {
            level_1_parent_id: id,
          },
          {
            level_2_parent_id: id,
          },
        ],
      };

      let query = {
        where,
        include: {
          level_2_children: true,
        },
      } as any;

      return await prisma.location.findFirst(query);
    } catch (error) {
      throw error;
    }
  }

  static async getOneByIdWithAllChildren(locationId: number) {
    return prisma.location.findUnique({
      where: { id: locationId },
      include: {
        level_1_children: {
          include: {
            level_2_children: true, // Modify this to include further nested levels as necessary
          },
        },
        level_2_children: true,
      },
    });
  }

  static async getLocationIdsToSearch(locationUuid: string) {
    // Fetch the source location by UUID
    const sourceLocation = await this.getOne(locationUuid);
    let childrenIds = [];

    // Fetch the location along with all its child locations
    const locationWithChildren = await this.getOneByIdWithAllChildren(
      sourceLocation.id
    );

    if (locationWithChildren) {
      // If there are level 1 children, map their IDs and their children's IDs
      if (locationWithChildren.level_1_children) {
        locationWithChildren.level_1_children.forEach((child) => {
          childrenIds.push(child.id);
          if (child.level_2_children) {
            childrenIds = childrenIds.concat(
              child.level_2_children.map((c) => c.id)
            );
            // Add additional levels here if necessary
          }
        });
      }

      // Map direct level 2 children's IDs
      if (locationWithChildren.level_2_children) {
        childrenIds = childrenIds.concat(
          locationWithChildren.level_2_children.map((child) => child.id)
        );
      }
    }

    // Include the original location's ID
    childrenIds.push(sourceLocation.id);

    return childrenIds;
  }
}
