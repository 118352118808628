export default class ListingDescriptionHelper {
  static getQueryPramsInfo(
    queryParams: any,
    locationName: string = null,
    locale: "" | "en" = ""
  ) {
    let offerText: string = "";

    switch (queryParams.listing_type) {
      case "sale":
        offerText = locale == "en" ? "For Sale" : "uzwa";
        break;

      case "rent":
        offerText = locale == "en" ? "For Rent" : "pangishwa";
        break;

      default:
        offerText = null;
    }

    let descriptionData = this.getDescriptionData(
      offerText,
      queryParams,
      locale
    );

    let locationConnectorEn =
      locationName.split(", ").length > 1 ? " at " : " in ";

    let locationText = locationName
      ? locale == "en"
        ? ` ${locationConnectorEn} ${locationName}`
        : ` ${locationName}`
      : null;

    locationText = locationText
      ? locationText
      : locale == "en"
      ? " in Tanzania"
      : " Tanzania";

    let easilyAndFast =
      locale == "en" ? " easily and fast" : " kwa haraka na urahisi";

    let find = locale == "en" ? "Find" : "Tafuta";

    return {
      seoTitle: `${descriptionData.propertyType}${descriptionData.offerSEO}${locationText}`,
      seoDescription:
        this.toSentenceCase(
          `${find} ${descriptionData.propertyType}${descriptionData.offer}${easilyAndFast}`
        ) + (locationName ? locationText : ""),
      pageTitle: `${descriptionData.propertyType}${descriptionData.offerSEO}${locationText}`,
      pageDescription:
        this.toSentenceCase(
          `${find} ${descriptionData.propertyType}${descriptionData.offer} `
        ) + locationText,
    };
  }

  static getDescriptionData(
    offerText: string,
    queryParams: any,
    locale: "" | "en" = ""
  ) {
    switch (queryParams.property_type) {
      case "residential_plot":
        return {
          propertyType: locale == "en" ? "Plots" : "Viwanja",
          offerSEO: this.getOfferText(offerText, "vina", locale),
          offer: this.getOfferText(offerText, "vinavyo", locale),
        };

      case "farm":
        return {
          propertyType: locale == "en" ? "Farms" : "Mashmba",
          offerSEO: this.getOfferText(offerText, "vina", locale),
          offer: this.getOfferText(offerText, "vinavyo", locale),
        };

      case "house":
        return {
          propertyType: locale == "en" ? "Houses" : "Nyumba",
          offerSEO: this.getOfferText(offerText, "zina", locale),
          offer: this.getOfferText(offerText, "zinazo", locale),
        };

      case "retail_space":
        return {
          propertyType: locale == "en" ? "Retail Space" : "Frame za Biashara",
          offerSEO: this.getOfferText(offerText, "zina", locale),
          offer: this.getOfferText(offerText, "zinazo", locale),
        };

      case "office_space":
        return {
          propertyType: locale == "en" ? "Office Space" : "Ofisi",
          offerSEO: this.getOfferText(offerText, "zina", locale),
          offer: this.getOfferText(offerText, "zinazo", locale),
        };

      case "warehouse":
        return {
          propertyType:
            locale == "en" ? "Warehouse Godown)" : "Maghala (Godown)",
          offerSEO: this.getOfferText(offerText, "yana", locale),
          offer: this.getOfferText(offerText, "yanayo", locale),
        };

      case "industrial_plot":
        return {
          propertyType:
            locale == "en"
              ? "Industrial and Commercial Plots"
              : "Viwanja vya Viwanda na Biashara",
          offerSEO: this.getOfferText(offerText, "vina", locale),
          offer: this.getOfferText(offerText, "vinavyo", locale),
        };

      default:
        return {
          propertyType: locale == "en" ? "Properties" : "Viwanja na Nyumba",
          offerSEO: this.getOfferText(offerText, "zinazo", locale),
          offer: this.getOfferText(offerText, "zinazo", locale),
        };
    }
  }

  static getOfferText(
    offerText: string,
    swSubscript: string,
    locale: "" | "en" = ""
  ) {
    if (!offerText) return "";
    let offer =
      locale == "en" ? " " + offerText : " " + swSubscript + offerText;
    return offer;
  }

  static toSentenceCase(str: string) {
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
